export const initialState = {}

const forms = {
  SAVE_MEDICAL_HISTORY: "SAVE_MEDICAL_HISTORY",
  SAVE_KESSLER_6: "SAVE_KESSLER_6",
  SAVE_CSSRS: "SAVE_CSSRS",
  RESET_DETAILS: "RESET_DETAILS",
}

export const MentalHealthReducer = (state, action) => {
  switch (action.type) {
    case forms.SAVE_MEDICAL_HISTORY:
    case forms.SAVE_KESSLER_6:
    case forms.SAVE_CSSRS:
      return { ...state, ...action.payload }
    case forms.RESET_DETAILS:
    default:
      return { ...initialState }
  }
}
